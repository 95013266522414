
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["less_body", "more_body", "more_link", "less_link"]

  show_more(event) {
    event.preventDefault();
    this.more_bodyTarget.classList.remove('d-none')
    this.less_bodyTarget.classList.add('d-none')

    this.less_linkTarget.classList.remove('d-none')
    this.more_linkTarget.classList.add('d-none')
  }
  show_less(event) {
    event.preventDefault();
    this.less_bodyTarget.classList.remove('d-none')
    this.more_bodyTarget.classList.add('d-none')

    this.less_linkTarget.classList.add('d-none')
    this.more_linkTarget.classList.remove('d-none')
  }
}
