
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["projects_field", "images_field", "video_field", "video_field_multiple", "post_type_input_field", "content_type_input_field", "image_ratio_info", "image_format_info"]

  connect() {
    // console.log("post_type_input_field", this.post_type_input_fieldTarget.value)
    // console.log("content_type_input_field", this.content_type_input_fieldTarget.value)
    if ((this.post_type_input_fieldTarget.value == "marketing" || this.post_type_input_fieldTarget.value == "testimonial") && (this.content_type_input_fieldTarget.value == "images")) {
      this.image_ratio_infoTarget.classList.remove('d-none')
      this.image_format_infoTarget.classList.add('d-none')
    } else if ((this.post_type_input_fieldTarget.value == "design") && (this.content_type_input_fieldTarget.value == "images")) {
      this.image_format_infoTarget.classList.remove('d-none')
      this.image_ratio_infoTarget.classList.add('d-none')
    } else {
      this.image_format_infoTarget.classList.add('d-none')
      this.image_ratio_infoTarget.classList.add('d-none')
    }
  }

  post_type_change(event) {
    event.preventDefault();
    // console.log(event.target.value)

    // console.log("post_type_input_field", this.post_type_input_fieldTarget.value)
    // console.log("content_type_input_field", this.content_type_input_fieldTarget.value)
    if ((this.post_type_input_fieldTarget.value == "marketing" || this.post_type_input_fieldTarget.value == "testimonial") && (this.content_type_input_fieldTarget.value == "images")) {
      this.image_ratio_infoTarget.classList.remove('d-none')
      this.image_format_infoTarget.classList.add('d-none')
    } else if ((this.post_type_input_fieldTarget.value == "design") && (this.content_type_input_fieldTarget.value == "images")) {
      this.image_format_infoTarget.classList.remove('d-none')
      this.image_ratio_infoTarget.classList.add('d-none')
    } else {
      this.image_format_infoTarget.classList.add('d-none')
      this.image_ratio_infoTarget.classList.add('d-none')
    }

    if (event.target.value == "design") {
      this.projects_fieldTarget.classList.remove('d-none')
    } else if (event.target.value == "marketing") {
      this.projects_fieldTarget.classList.add('d-none')
    } else if (event.target.value == "testimonial") {
      this.projects_fieldTarget.classList.add('d-none')
    }
  }

  content_type_change(event) {
    event.preventDefault();
    // console.log(event.target.value)

    // console.log("post_type_input_field", this.post_type_input_fieldTarget.value)
    // console.log("content_type_input_field", this.content_type_input_fieldTarget.value)
    if ((this.post_type_input_fieldTarget.value == "marketing" || this.post_type_input_fieldTarget.value == "testimonial") && (this.content_type_input_fieldTarget.value == "images")) {
      this.image_ratio_infoTarget.classList.remove('d-none')
      this.image_format_infoTarget.classList.add('d-none')
    } else if ((this.post_type_input_fieldTarget.value == "design") && (this.content_type_input_fieldTarget.value == "images")) {
      this.image_format_infoTarget.classList.remove('d-none')
      this.image_ratio_infoTarget.classList.add('d-none')
    } else {
      this.image_format_infoTarget.classList.add('d-none')
      this.image_ratio_infoTarget.classList.add('d-none')
    }



    if (event.target.value == "images") {
      this.video_fieldTarget.classList.add('d-none')
      this.images_fieldTarget.classList.remove('d-none')
    } else if (event.target.value == "video") {
      this.images_fieldTarget.classList.add('d-none')
      this.video_fieldTarget.classList.remove('d-none')
    }

  }

}
