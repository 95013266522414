
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["projects_field", "btn_submit"]


  post_type_change(event) {
    event.preventDefault();
    console.log(event.target.value)

    if (event.target.value == "design") {
      this.projects_fieldTarget.classList.remove('d-none')
    } else if (event.target.value == "marketing") {
      this.projects_fieldTarget.classList.add('d-none')
    } else if (event.target.value == "testimonial") {
      this.projects_fieldTarget.classList.add('d-none')
    }
  }

  content_type_change(event) {
    event.preventDefault();
    console.log(event.target.value)

    if (event.target.value == "images") {
      this.btn_submitTarget.value = "Next"
    } else if (event.target.value == "video") {
      this.btn_submitTarget.value = "Submit"
    }

  }
}
