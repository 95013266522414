import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["links", "template"]

  // connect() {
  //   console.log("Hello, Stimulus!", this.element)
  // }

  add_association(event) {
    event.preventDefault();
    console.log("called")
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault();
    let wrapper = event.target.closest(".nested_fields")
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      const input_fields = wrapper.querySelectorAll("input")
      input_fields.forEach(input_field => {
        input_field.removeAttribute("required")
      });
      wrapper.style.display = 'none'
    }
  }
}
